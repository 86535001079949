<template>
    <div class="qa-detail-page">
        <full-loading ref="fullLoading"></full-loading>
        <page-title :title="info.title||''" style="border: none;" v-if="info">
            <template slot="info">
                <div class="more-info">
                    <el-popover trigger="hover">
                        <el-form label-width="80px" size="mini" class="more-info-popover">
                            <el-form-item label="语言方向" v-if="LANGUAGE_DICT&&info">
                                {{LANGUAGE_DICT[info.sourceLang].name}} <i class="el-icon-arrow-right"></i> {{LANGUAGE_DICT[info.targetLang].name}}
                            </el-form-item>
                            <el-form-item label="问题数">{{(info.aiCount + info.basicCount)}} 处</el-form-item>
                            <el-form-item label="创建时间">{{info.createTime}}</el-form-item>
                        </el-form>
                        <svg-icon name="ib-info" class-name="color-555" slot="reference"></svg-icon>
                    </el-popover>
                </div>
            </template>
            <template slot="tools">
                <!-- <div style="width:160px;">
                    <el-input placeholder="输入关键词" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div> -->
                <div style="width:160px;">
                    <el-select v-model="searchForm.filter" @change="filterChange" size="small" clearable placeholder="选择筛选条件">
                      <el-option label="仅显示标记句段" value="MARKED"></el-option>
                      <el-option label="仅显示无标记句段" value="UNMARKED"></el-option>
                    </el-select>
                </div>
                <div class="margin-l-10" v-permission="`tools:check:detailRouter:download`">
                    <el-dropdown @command="downloadResult">
                        <el-button type="primary" size="small" round icon="el-icon-download">导出报告<i class="el-icon-arrow-down el-icon--right margin-l-10"></i></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="all">导出全部</el-dropdown-item>
                            <el-dropdown-item command="MARKED">仅标记部分</el-dropdown-item>
                            <el-dropdown-item command="UNMARKED">仅无标记部分</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </template>
        </page-title>
        <el-alert type="info" center effect="dark" :closable="false">
            <template slot="title">
                <span class="fonts-12 margin-l-10 text-weight-400" v-if="info">
                    <span>共检查出 <span class="color-error">{{(info.aiCount||0) + (info.basicCount||0)}}</span> 处疑似错误，QA报告只展示有质量问题的句段</span>
                    <template v-if="info.basicSubtask && info.basicSubtask.status === 'F'">
                        <span class="color-error margin-l-20">规则检查失败</span>
                    </template>
                    <template v-if="info.aiSubtask && info.aiSubtask.status === 'F'">
                        <span class="color-error margin-l-20">智能检查失败</span>
                    </template>
                </span>
            </template>
        </el-alert>
        <el-table class="qa-table-header" style="width: 100%" >
            <el-table-column label="原句段位置" width="300"></el-table-column>
            <el-table-column :label="`原文（${info && LANGUAGE_DICT && LANGUAGE_DICT[info.sourceLang] && LANGUAGE_DICT[info.sourceLang].name}）`"></el-table-column>
            <el-table-column :label="`译文（${info && LANGUAGE_DICT && LANGUAGE_DICT[info.targetLang] && LANGUAGE_DICT[info.targetLang].name}）`"></el-table-column>
            <el-table-column label="QA" width="300"></el-table-column>
            <el-table-column label="标记" width="120"></el-table-column>
            <template slot="append">
                <template v-if="typeList.length">
                    <el-collapse accordion class="qa-collapse" @change="loadTypeChild">
                        <template v-for="(item, index) in typeList">
                            <el-collapse-item :name="item.code" :key="index">
                                <template slot="title">
                                    <div class="flex-1 flex flex-justify-between">
                                        <div class="padding-l-15 fonts-15 text-weight-600">
                                            {{item.description}}<span class="margin-l-10 color-error">{{item.count}}</span>
                                            <template v-if="item.code === activeType && loading">
                                                <span class="margin-l-10"><i class="el-icon-loading"></i></span>
                                            </template>
                                        </div>
                                        <div class="flex flex-align-center" style="width: 84px;">
                                            <template v-if="item.code === activeType && childList.length">
                                                <el-tooltip content="全部标记" placement="top">
                                                    <el-button type="text" size="mini" style="font-size: 15px;" :loading="markedLoading" @click.stop="setTypeMark(true)"><svg-icon name="ib-dot-1" className="color-error" v-if="!markedLoading"></svg-icon></el-button>
                                                </el-tooltip>
                                                <el-tooltip content="全部取消标记" placement="top">
                                                    <el-button type="text" size="mini" style="font-size: 15px; color: #cdcdcd; margin-left: 30px;" :loading="markedLoading" @click.stop="setTypeMark(false)"><svg-icon name="ib-dot-1" v-if="!markedLoading"></svg-icon></el-button>
                                                </el-tooltip>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="item.code === activeType">
                                    <el-table :data="childList" :show-header="false" max-height="720" style="width: 100%" v-loading="loading">
                                        <el-table-column label="原句段位置" prop="filename" width="300"></el-table-column>
                                        <el-table-column label="原文(中文)">
                                            <template slot-scope="scope">
                                                <p v-html="scope.row.sourceTaggedText"></p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="译文(英文)">
                                            <template slot-scope="scope">
                                                <p v-html="scope.row.targetTaggedText"></p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="QA" width="300">
                                            <template slot-scope="scope">
                                                <p v-html="scope.row.problemDescription"></p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="标记" width="110">
                                            <template slot-scope="scope">
                                                <span class="mark-btn" :class="{'marked': scope.row.marked}" @click.stop="setMark(scope)">
                                                    <svg-icon name="ib-dot-1"></svg-icon>
                                                </span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-collapse-item>
                        </template>
                    </el-collapse>
                </template>
                <template v-else>
                    <div class="none-data-block">暂无错误信息</div>
                </template>
            </template>
        </el-table>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import FullLoading from '@/components/FullLoading';
import config from '@/utils/config';
import { toolsApi, commonApi } from '@/utils/api';
import PageTitle from '@/components/PageTitle';
export default {
    components:{PageTitle, FullLoading},
    data(){
        return {
            downloadLoading:false,
            info:null,
            dialog:{
                show:false,
            },
            searchForm:{
                kw:'',
                filter: '',
            },
            searchTimer: null,
            activeTab:'all',
            list:[],
            total:0,
            pageSize:30,
            pageNumber:1,
            pollingTimer: null,

            typeList: [],
            childList: [],
            loading: false,
            activeType: null,
            markedLoading: false,
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods:{
        initQaTypes() {
            let url = `${toolsApi.queryQAResultTypes}?taskId=${this.$route.query.id}`;
            if(this.searchForm.filter) {
                url = `${url}&filterType=${this.searchForm.filter}`;
            }
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.info = { ...res.data.task, basicSubtask: res.data.basicSubtask, aiSubtask: res.data.aiSubtask};
                    this.typeList = res.data.types;
                }
            }).finally(() => {
                if(this.activeType) {
                    this.loadTypeChild(this.activeType);
                } 
            });
        },
        loadTypeChild(name) {
            this.childList = [];
            this.activeType = name;
            if (!name) {
                this.initQaTypes();
                return;
            }
            let url = `${toolsApi.queryQAResultListByType}?taskId=${this.$route.query.id}&type=${name}`;
            if(this.searchForm.filter) {
                url = `${url}&filterType=${this.searchForm.filter}`;
            }
            this.loading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.childList = res.data;
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        setMark(scope){
            let index = scope.$index;
            let url = toolsApi.setProblemMark;
            let postData = {
                taskId: this.$route.query.id,
                problemIdList: [scope.row.problemId],
                marked: !scope.row.marked,
                problemType: this.activeType,
            };
            this.markedLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.childList[index].marked = !this.childList[index].marked;
                }
            }).finally(() =>{
                this.markedLoading = false;
            })
        },
        setTypeMark(marked){
            let url = toolsApi.setProblemMark;
            let ids = [];
            this.childList.map(item => {
                ids.push(item.problemId);
            });
            let postData = {
                taskId: this.$route.query.id,
                problemIdList: ids,
                marked: marked,
                problemType: this.activeType,
            };
            this.markedLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    let rst = [];
                    this.childList.map(item => {
                        rst.push({...item, marked});
                    });
                    this.childList = rst;
                }
            }).finally(() =>{
                this.markedLoading = false;
            })
        },
        filterChange(val) {
            this.childList = [];
            this.initQaTypes();
        },
        downloadResult(command){
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: this.info.title,
                metadataIdList: [this.$route.query.id],
                metadataType: 'TOOLKIT_QA',
                moreOptions: {
                    filterType: command !== 'all' ? command : null,
                },
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                this.downloadLoading = false;
            })
        },
    },
    mounted(){
        this.initQaTypes();
    },
    beforeDestroy() {
       
    },
}
</script>
<style lang="scss" scoped>
.qa-detail-page{
    .qa-type-title{
        font-weight: 600;
        font-size: 12px;
        margin-bottom:5px;
    }
    .qa-result-content{
        ::v-deep p{
            font-size: 14px;
            padding:5px 0;
        }
    }
    .more-info {
        font-size: 20px;
    }


    .qa-table-header {
        margin-bottom: 5px;
        &::before {
            height: 0;
        }
        &.el-table {
            background-color: transparent;
        }
        ::v-deep .el-table__empty-block{
            display: none;
        }
        ::v-deep .el-table__header {
            thead {
                color: #666666;
                font-size: 14px;
                tr {
                    border-radius: 10px; 
                }
                th.is-leaf {
                    border: none;
                    &:first-child {
                        border-radius: 10px 0 0 10px;    
                    }
                    &:nth-child(5) {
                        border-radius: 0 10px 10px 0;    
                    }
                }
            }
        }
    }
    .qa-collapse {
        border: none;
        ::v-deep .el-collapse-item {
            .el-collapse-item__header {
                border-radius: 10px;
                border: none;
                margin-top: 5px;
                &.is-active {
                    border-radius: 10px 10px 0 0;
                }
                i {
                    font-size: 18px;
                }
            }
            .el-collapse-item__wrap {
                border: none;
                border-radius: 0 0 10px 10px;
                .el-collapse-item__content{
                    padding-bottom: 0;
                }
            }
        }
    }
    .none-data-block {
        margin-top: 5px;
        background-color: #ffffff;
        border-radius: 10px;
        text-align: center;
        padding: 15px 0;
        font-size: 14px;
    }
    .mark-btn {
        font-size: 15px;
        color: #cdcdcd;
        cursor: pointer;
        &.marked {
            color: #F56C6C;
        }
    }

}
</style>
<style lang="scss">
.qa-detail-page{
    .el-alert--info.is-dark {
        background-color: #f7efe0;
        color: #000000;
        border-radius: 0px;
    }
    .el-alert.is-dark .el-alert__closebtn {
        color: #000000;
        font-size:16px;
        margin-top:-2px;
    }
}
</style>
